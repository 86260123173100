<!-- -->
<template>
  <div class="header">
    <div class="logo">
      <img src="../assets/images/logo.png" alt="LOGO" />
    </div>
    <div class="menu">
      <div
        class="menuItem"
        v-for="(item,i) in menuList"
        :key="i"
        @click="goToMenu(item.url)"
        :class="item.url==index?'active':''"
        v-show="item.status"
      >{{item.text}}</div>
    </div>
    <div class="menuBar">
      <div class="barItem" @mouseover="ershow(1)" @mouseleave="ershow(2)">
        <span>手机下单</span>
        <img src="../assets/images/erqeima.png" alt="二维码" class="erweima" v-show="erweiShow" />
      </div>
      <div class="barItem">
        <span>使用指南</span>
      </div>
      <div class="barItem">
        <span>量体介绍</span>
      </div>
      <div class="barItem">
        <el-badge :value="5" class="badgeI" type="info">
          <i class="el-icon-bell"></i>
        </el-badge>
      </div>
      <div class="barItem">
        <i class="el-icon-user"></i>
      </div>
      <div class="barItem">
        <el-badge :value="5" class="badgeI" type="info">
          <i class="el-icon-shopping-cart-2"></i>
        </el-badge>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      menuList: [
        { id: 1, url: '/', text: '首页', status: true },
        { id: 2, url: '/customized', text: '下单', status: true },
        { id: 3, url: '/orders', text: '订单', status: false },
        { id: 4, url: '/about', text: '资金', status: false },
        { id: 5, url: '/about', text: '客户', status: false },
        { id: 6, url: '/about', text: '地址', status: false },
        { id: 7, url: '/about', text: '商城', status: true },
        { id: 8, url: '/about', text: '平台介绍', status: true },
      ],
      index: sessionStorage.getItem('url') ? sessionStorage.getItem('url') : 0,
      erweiShow: false,
    }
  },
  computed: {},
  watch: {},
  created() {
    // 判断是否登录
    if (sessionStorage.getItem('codes')) {
      for (let index = 0; index < this.menuList.length; index++) {
        this.menuList[index].status = true
      }
    }
  },
  mounted() {},
  methods: {
    // 跳转页面
    goToMenu(menuUrl) {
      // 浏览器存储路径
      sessionStorage.setItem('url', menuUrl)
      // 判断是否登录
      if (sessionStorage.getItem('codes')) {
        this.index = menuUrl
        this.$router.push(menuUrl)
      } else {
        this.$message.error('请先登陆')
        this.$router.push('/login')
      }
    },
    // 二维码
    ershow(i) {
      if (i == 1) {
        this.erweiShow = true
      } else {
        this.erweiShow = false
      }
    },
  },
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  height: 80px;
  font-size: 16px;
  background-color: #004d4d;
  .logo {
    flex: 1;
    max-width: 200px;
    margin-top: 10px;
    margin-left: 80px;
    width: 200px;
    height: 60px;
    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}
.menu {
  flex: 3;
  display: flex;
  margin-left: 20px;
  .menuItem {
    flex: 1;
    display: flex;
    max-width: 80px;
    justify-content: center;
    align-items: center;
    color: #bfbfbf;
    cursor: pointer;
  }
  .menuItem:last-child {
    min-width: 80px;
  }
  .active {
    color: #fff;
  }
}
.menuBar {
  flex: 3;
  display: flex;
  justify-content: flex-end;
  .barItem {
    position: relative;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #bfbfbf;
    max-width: 90px;
    min-width: 90px;
    cursor: pointer;
    i {
      font-size: 30px;
      color: #bfbfbf;
    }
    .erweima {
      position: absolute;
      top: 67px;
      left: -83px;
    }
  }
}

/* 屏幕小于1440px */
@media screen and (max-width: 1440px) {
  .header {
    display: flex;
    height: 60px;
    font-size: 14px;
    background-color: #004d4d;
    .logo {
      margin-top: 5px;
      margin-left: 20px;
      width: 155px;
      height: 50px;
    }
  }
  .menu {
    .menuItem {
      max-width: 45px;
    }
    .menuItem:last-child {
      min-width: 80px;
    }
    .active {
      color: #fff;
    }
  }
  .menuBar {
    .barItem {
      max-width: 70px;
      min-width: 70px;

      i {
        font-size: 20px;
      }
      .erweima {
        top: 47px;
      }
    }
  }
}
</style>
