<!-- -->
<template>
  <div class="footer">
    <div class="corss">
      © 2021-2022 3D-DESIGN - 江苏称意智能科技有限公司
      <a
        href="https://beian.miit.gov.cn/"
        target="_blank"
      >苏ICP备18005140号-8</a>
      <span @click="dialogVisible = true">意见建议</span>
      <span @click="goToHe">申请合作</span>
    </div>
    <!-- 新增 -->
    <el-dialog title="意见建议" :visible.sync="dialogVisible" width="700px">
      <div class="yijian">
        <div class="ptext">
          <span>尊敬的用户：</span>
          <span>非常感谢您使用我公司产品，我们在不断提升使用体验和优化定制服务的同时，难免会有疏漏或不足，非常期待您在使用过程中发现问题，并提出宝贵的意见或建议，对此我们表示由衷的感谢。</span>
        </div>
        <div class="yiform">
          <div class="typeStyle">
            <span class="title">建议类型</span>
            <div class="typeItem">
              <div class="itemInputs" @click="haldItem(1)" :class="this.optype==1?'actt':''">
                <span>功能建议</span>
                <i class="el-icon-success"></i>
              </div>
              <div class="itemInputs" @click="haldItem(2)" :class="this.optype==2?'actt':''">
                <span>体验反馈</span>
                <i class="el-icon-success"></i>
              </div>
              <div class="itemInputs" @click="haldItem(3)" :class="this.optype==3?'actt':''">
                <span>缺陷反馈</span>
                <i class="el-icon-success"></i>
              </div>
            </div>
          </div>
          <div class="typeStyle">
            <span class="title">反馈内容</span>
            <div class="typeItems">
              <textarea name id cols="30" rows="4" v-model="contents"></textarea>
              <span class="rt">请上传问题截图（非必传）</span>
              <div class="upthree">
                <a href="javascript:;" class="a-upload">
                  <input type="file" @change="addFile1" ref="inputer1" />
                  <div class="upBefore" v-show="!jietu1">
                    <i class="el-icon-upload"></i>
                    <span>上传图片</span>
                  </div>
                  <div class="upafter" v-show="jietu1">
                    <img :src="jietuImg1" alt />
                    <div class="afterHover">
                      <span>重传</span>
                    </div>
                  </div>
                </a>
                <a href="javascript:;" class="a-upload" v-show="imgb">
                  <input type="file" @change="addFile2" ref="inputer2" />
                  <div class="upBefore" v-show="!jietu2">
                    <i class="el-icon-upload"></i>
                    <span>上传图片</span>
                  </div>
                  <div class="upafter" v-show="jietu2">
                    <img :src="jietuImg2" alt />
                    <div class="afterHover">
                      <span>重传</span>
                    </div>
                  </div>
                </a>
                <a href="javascript:;" class="a-upload" v-show="imgc">
                  <input type="file" @change="addFile3" ref="inputer3" />
                  <div class="upBefore" v-show="!jietu3">
                    <i class="el-icon-upload"></i>
                    <span>上传图片</span>
                  </div>
                  <div class="upafter" v-show="jietu3">
                    <img :src="jietuImg3" alt />
                    <div class="afterHover">
                      <span>重传</span>
                    </div>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="cy" @click="sureClick">确认</el-button>
        <el-button plain @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
    <!-- 反馈成功 -->
    <el-dialog title="意见建议" :visible.sync="dialogVisibles" width="700px">
      <div class="yisuccess">
        <i class="el-icon-success"></i>
        <span class="title">提交成功</span>
        <span
          class="ptext"
        >您的反馈已提交成功，称意快定团队将会认真研究分析，尽快优化相关功能和完善相关服务，精心打磨每一个细节，持续为您提供优质的体验和服务；在改善过程中，如果对您使用造成不便，请多多谅解。</span>
        <span class="ptext">如遇到的问题对您来说非常重要，可拨打19505056811（王先生）联系我们，我们将在第一时间为您处理。</span>
        <span class="ptext">再次感谢您的关注与支持，谢谢！</span>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button plain @click="dialogVisibles = false">关闭</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import base from '../untils/base'
export default {
  name: '',
  components: {},
  props: {},
  data() {
    return {
      dialogVisible: false,
      dialogVisibles: false,
      formData: new FormData(),
      file: {}, //文件数据
      imgFile: {},
      jietu1: false,
      jietu2: false,
      jietu3: false,
      jietuImg1: '',
      jietuImg2: '',
      jietuImg3: '',
      optype: 1,
      contents: '',
      imgb: false,
      imgc: false,
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {
    addFile3: function () {
      // console.log(this.editForm.file)

      var _this = this
      let inputDOM = this.$refs.inputer3
      // let oldLen = this.filLen;
      this.file = inputDOM.files[0]
      let len = this.file.length
      let size = Math.floor(this.file.size / 1024)
      if (size > 20 * 1024 * 1024) {
        alert('请选择20M以内的图片！')
        return false
      }

      this.formData.append('file', this.file)
      this.formData.append('jietu3', this.file)
      this.jietuImg3 = URL.createObjectURL(this.file)
      this.jietu3 = true
    },
    addFile2: function () {
      var _this = this
      let inputDOM = this.$refs.inputer2
      // let oldLen = this.filLen;
      this.file = inputDOM.files[0]
      let len = this.file.length
      let size = Math.floor(this.file.size / 1024)
      if (size > 20 * 1024 * 1024) {
        alert('请选择20M以内的图片！')
        return false
      }

      this.formData.append('file', this.file)
      this.formData.append('jietu2', this.file)
      this.jietuImg2 = URL.createObjectURL(this.file)
      this.jietu2 = true
      this.imgc = true
    },
    addFile1: function () {
      var _this = this
      let inputDOM = this.$refs.inputer1
      // let oldLen = this.filLen;
      this.file = inputDOM.files[0]
      let len = this.file.length
      let size = Math.floor(this.file.size / 1024)
      if (size > 20 * 1024 * 1024) {
        alert('请选择20M以内的图片！')
        return false
      }

      this.formData.append('file', this.file)
      this.formData.append('jietu1', this.file)
      this.jietuImg1 = URL.createObjectURL(this.file)
      this.jietu1 = true
      this.imgb = true
    },
    haldItem(i) {
      this.optype = i
    },
    sureClick() {
      let _this = this
      this.formData.append('codes', sessionStorage.getItem('codes'))
      this.formData.append('contents', this.contents)
      this.formData.append('optype', this.optype)
      axios({
        method: 'post',
        url: `${base.url}/loginapi/doaddopinion`,
        data: this.formData,
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(function (res) {
          if (res.data.status == 1) {
            console.log(res)
            _this.dialogVisibles = true
            _this.dialogVisible = false
          } else {
            _this.$message.error(res.data.msg)
          }
        })
        .catch(function (err) {
          // console.log('新建分享', err)
        })
    },
    goToHe() {
      this.$router.push('/cooperate')
    },
  },
}
</script>

<style lang="less" scoped>
.footer {
  .corss {
    height: 36px;
    line-height: 36px;

    font-size: 12px;
    border-top: 4px solid #004d4d;
    background-color: #000;
    text-align: center;
    color: #fff;
    span {
      float: right;
      cursor: pointer;
      margin-right: 10px;
    }
    span:first-child {
      margin-right: 60px;
    }
    a {
      color: #fff;
      text-decoration: none;
      margin-left: 5px;
    }
  }
  .yisuccess {
    i {
      display: block;
      font-size: 60px;
      color: #004d4d;
      text-align: center;
    }
    .title {
      display: block;
      margin: 10px 0;
      font-size: 20px;
      text-align: center;
    }
    .ptext {
      display: block;
      text-indent: 2em;
      font-size: 14px;
    }
  }
  .yiform {
    margin-top: 10px;
  }
  .typeStyle {
    .title {
      float: left;
      height: 20px;
      width: 70px;
      line-height: 20px;
      color: #1a1a1a;
      font-size: 16px;
      border-left: 3px solid #4e4e4e;
      padding-left: 10px;
      margin-top: 8px;
      margin-right: 20px;
    }
    .typeItem {
      display: flex;
      .itemInputs {
        max-width: 170px;
        min-width: 170px;
        margin-right: 20px;
        border: 1px solid #dcdfe6;
        color: #808080;
        height: 36px;
        font-size: 16px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        box-sizing: border-box;
        margin-bottom: 20px;
        span {
          padding-left: 10px;
        }
        i {
          padding-right: 10px;
          font-size: 16px;
        }
      }
      .actt {
        border: 1px solid #004d4d;
        color: #fff;
        background-color: #004d4d;
        cursor: pointer;
      }
      .itemInputs:hover {
        border: 1px solid #004d4d;
        color: #fff;
        background-color: #004d4d;
        cursor: pointer;
      }
    }
    .typeItems {
      textarea {
        width: 550px;
        border-radius: 0;
        border: 1px solid #dcdfe6;
      }
      textarea:focus {
        border: 1px solid #004d4d;
      }
      textarea:hover {
        border: 1px solid #004d4d;
      }
      textarea:active {
        border: 1px solid #004d4d;
      }
      .rt {
        display: block;
        margin-left: 100px;
      }
    }
  }
  .upthree {
    margin-left: 100px;
  }
  .a-upload {
    display: block;
    height: 90px;
    width: 90px;
    position: relative;
    cursor: pointer;
    color: #bfbfbf;
    background: #fff;
    border: 1px solid #ddd;
    margin-right: 10px;
    // border-radius: 4px;
    overflow: hidden;
    text-decoration: none;
    display: inline-block;
    *display: inline;
    *zoom: 1;
    .upBefore {
      text-align: center;
      i {
        display: block;
        font-size: 32px;
        margin-top: 20px;
      }
      span {
        display: block;
        font-size: 14px;
      }
    }
    .upafter {
      img {
        width: 100%;
        height: 100%;
      }
      .afterHover {
        span {
          position: absolute;
          left: 10px;
          top: 0;
          width: 70px;
          height: 30px;
          line-height: 30px;
          text-align: center;
          font-size: 14px;
          background: rgba(0, 0, 0, 0.4);
          border-radius: 20px;
          top: 30px;
        }
        i {
          position: absolute;
          bottom: 0;
          right: 0;
          width: 0.208rem;
          height: 0.208rem;
          font-size: 16px;
          text-align: center;
          line-height: 0.208rem;
          background: rgba(0, 0, 0, 0.4);
          z-index: 3;
        }
      }
    }
  }
  .a-upload input {
    position: absolute;
    font-size: 14px;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    filter: alpha(opacity=0);
    cursor: pointer;
    z-index: 999;
  }
  .a-upload:hover {
    // color: #004d4d;
    border-color: #004d4d;
    .afterHover {
      span {
        background: rgba(0, 77, 77, 0.6);
        color: #fff;
      }
    }
  }
  .a-upload {
    .afterHover {
      span:hover {
        background: rgba(0, 77, 77, 0.6);
        color: #fff;
      }
    }
  }
  .a-upload {
    .afterHover {
      i:hover {
        background: rgba(0, 77, 77, 0.6);
        color: #fff;
      }
    }
  }
  .zTitle {
    display: block;
    text-align: center;
    color: #808080;
    margin-top: 10px;
    font-size: 0.09375rem;
  }
}
</style>
