//域名统一管理
const base = {
  // 正式服务器
  url: 'https://md.3ddzy.com/newfastapiv1',
  // 测试服务器
  // url: 'https://tfast.jscytech.com/newfastapiv1',
  urlss: 'https://tfast.jscytech.com/newfastapiv1',
  urlImages: 'https://md.3ddzy.com',
}
// if (window.location.hostname == 'www.cy-kd.cn') {
//   base.url = 'https://md.3ddzy.com/newfastapiv1'
// } else {
//   base.url = 'https://tfast.jscytech.com/newfastapiv1'
// }
export default base
