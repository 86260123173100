import Vue from 'vue'
import VueRouter from 'vue-router'
import Main from '../views/Main.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Main',
    component: Main,
    redirect: '/home',
    children: [
      {
        // 首页
        path: '/home',
        name: 'Home',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Home.vue'),
      },
      {
        // 公告
        path: '/news',
        name: 'News',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/news.vue'),
      },
      {
        // 款式
        path: '/custfashion',
        name: 'CustFashion',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/CustFashion.vue'),
      },
      {
        // 净体量体
        path: '/sizejing',
        name: 'Sizejing',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Sizejing.vue'),
      },
      {
        // 成衣尺寸
        path: '/sizecheng',
        name: 'Sizecheng',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Sizecheng.vue'),
      },
      {
        // 标准号加减
        path: '/sizetuan',
        name: 'Sizetuan',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Sizetuan.vue'),
      },
      {
        // 成衣尺寸
        path: '/sizejiajian',
        name: 'Sizejiajian',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Sizejiajian.vue'),
      },
      {
        // 定制
        path: '/customized',
        name: 'Customized',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Customized.vue'),
        children: [
          {
            // 面料
            path: '/custfabric',
            name: 'CustFabric',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/CustFabric.vue'),
          },
          {
            // 纽扣
            path: '/custclasp',
            name: 'CustClasp',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/CustClasp.vue'),
          },
          {
            // 个性
            path: '/custspecific',
            name: 'CustSpecific',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/CustSpecific.vue'
              ),
          },
        ],
      },
      {
        // 订单
        path: '/orders',
        name: 'Orders',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Orders.vue'),
      },
      {
        // 视频教程
        path: '/video',
        name: 'Video',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/video.vue'),
      },
      {
        // 订单
        path: '/orderPay',
        name: 'OrderPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/OrderPay.vue'),
      },
      {
        // 订单商品详情
        path: '/ordersDetail',
        name: 'OrdersDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/OrdersDetail.vue'),
      },
      {
        // 订单商品详情旧平台
        path: '/ordersDetails',
        name: 'OrdersDetails',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/OrdersDetails.vue'),
      },
      {
        // 商城
        path: '/shop',
        name: 'Shop',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Shop.vue'),
      },
      {
        // 商品详情
        path: '/shopdetail',
        name: 'ShopDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ShopDetail.vue'),
      },
      {
        // 购物车列表
        path: '/shopCart',
        name: 'ShopCart',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ShopCart.vue'),
      },
      {
        // 购物车确认付款
        path: '/shopCartPay',
        name: 'ShopCartPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ShopCartPay.vue'),
      },
      {
        // 申请售后
        path: '/orderShou',
        name: 'OrderShou',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/OrderShou.vue'),
      },
      {
        // 团单申请售后
        path: '/orderTuanShou',
        name: 'orderTuanShou',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/OrderTuanShou.vue'),
      },
      {
        // 售后列表
        path: '/shouhou',
        name: 'Shouhou',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Shouhou.vue'),
      },
      {
        // 确认付款
        path: '/shopPay',
        name: 'shopPay',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/shopPay.vue'),
      },
      {
        // 资金管理
        path: '/capital',
        name: 'Capital',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Capital.vue'),
      },
      {
        // 资金管理
        path: '/capitalCheck',
        name: 'CapitalCheck',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/CapitalCheck.vue'),
      },
      {
        // 客户管理
        path: '/custom',
        name: 'Custom',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Custom.vue'),
      },
      {
        // 客户管理编辑
        path: '/customDetail',
        name: 'CustomDetail',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/CustomDetail.vue'),
      },
      {
        // 客户管理添加
        path: '/customAdd',
        name: 'CustomAdd',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/CustomAdd.vue'),
      },
      {
        // 地址管理
        path: '/address',
        name: 'Address',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Address.vue'),
      },
      {
        // 账户信息
        path: '/user',
        name: 'User',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/User.vue'),
        children: [
          {
            // 我的账户
            path: '/usermy',
            name: 'UserMy',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/UserMy.vue'),
          },
          {
            // 我的子账号
            path: '/usertwo',
            name: 'UserTwo',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/UserTwo.vue'),
          },
          {
            // 修改登录密码
            path: '/userLogin',
            name: 'UserLogin',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/UserLogin.vue'),
          },
          {
            // 修改支付密码
            path: '/userpay',
            name: 'UserPay',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/UserPay.vue'),
          },
          {
            // 系统通知
            path: '/usernews/:id',
            name: 'UserNews',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/UserNews.vue'),
          },
          {
            // 系统通知
            path: '/userNewsdetail',
            name: 'UserNewsdetail',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/UserNewsdetail.vue'
              ),
          },
          {
            // 发票管理
            path: '/userinvoice',
            name: 'UserInvoice',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(
                /* webpackChunkName: "about" */ '../views/UserInvoice.vue'
              ),
          },
          {
            // 修改倍率
            path: '/userbl',
            name: 'Userbl',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/Userbl.vue'),
          },
          {
            // 修改倍率
            path: '/userModel',
            name: 'UserModel',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/UserModel.vue'),
          },
          {
            // 绑定微信
            path: '/userweixin',
            name: 'Userweixin',
            // route level code-splitting
            // this generates a separate chunk (about.[hash].js) for this route
            // which is lazy-loaded when the route is visited.
            component: () =>
              import(/* webpackChunkName: "about" */ '../views/Userweixin.vue'),
          },
        ],
      },
      {
        // 登陆
        path: '/login',
        name: 'Login',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Login.vue'),
      },
      {
        // 申请入驻
        path: '/cooperate',
        name: 'Cooperate',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Cooperate.vue'),
      },
      {
        // 物流
        path: '/logistics',
        name: 'Logistics',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Logistics.vue'),
      },
      {
        // 量体介绍
        path: '/introduce',
        name: 'Introduce',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Introduce.vue'),
      },
      {
        // 使用指南
        path: '/introduces',
        name: 'Introduces',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/Introduces.vue'),
      },
      {
        // 忘记密码
        path: '/forgotPass',
        name: 'ForgotPass',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/ForgotPass.vue'),
      },
      {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
          import(/* webpackChunkName: "about" */ '../views/About.vue'),
      },
    ],
  },

  {
    // 微信绑定成功
    path: '/weixinOk',
    name: 'weixinOk',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/weixinok.vue'),
  },

  {
    // 微信绑定成功
    path: '/weixinerr',
    name: 'weixinerr',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/weixinerr.vue'),
  },

  {
    // 微信绑定成功
    path: '/weixinoks',
    name: 'weixinoks',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/weixinoks.vue'),
  },

  {
    // 微信绑定成功
    path: '/iphoneorder',
    name: 'iphoneorder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ '../views/iphoneOrder.vue'),
  },
]

const router = new VueRouter({
  // mode: 'history',
  base: process.env.BASE_URL,
  routes,
})
// let EXPIRESTIME = 86400000
let EXPIRESTIME = 28800000
router.beforeEach((to, from, next) => {
  let starttime = localStorage.getItem('startTime')
  if (starttime) {
    let datas = new Date().getTime()
    if (datas - starttime > EXPIRESTIME) {
      localStorage.removeItem('codes')
      localStorage.removeItem('startTime')
      if (
        to.name !== 'Login' &&
        to.name !== 'Home' &&
        to.name !== 'ForgotPass' &&
        to.name !== 'News' &&
        to.name !== 'Cooperate' &&
        to.name !== 'weixinOk' &&
        to.name !== 'weixinerr' &&
        to.name !== 'iphoneorder'
      ) {
        next({ name: 'Login' })
      } else {
        next()
      }
    } else {
      next()
    }
  } else {
    if (
      to.name !== 'Login' &&
      to.name !== 'Home' &&
      to.name !== 'ForgotPass' &&
      to.name !== 'News' &&
      to.name !== 'Cooperate' &&
      to.name !== 'weixinOk' &&
      to.name !== 'weixinerr' &&
      to.name !== 'iphoneorder'
    ) {
      next({ name: 'Login' })
    } else {
      next()
    }
  }
})
export default router
