<template>
  <div class="home">
    <div class="container">
      <div class="header">
        <div class="logo">
          <img src="../assets/images/logo.svg" alt="LOGO" />
        </div>
        <div class="menu">
          <el-button type="cy" size="mini" v-for="(item, i) in menuList" :key="i" @click="goToMenu(item.url)"
            :class="item.url == index ? 'active' : ''" v-show="item.status">
            {{ item.text }}
          </el-button>
        </div>
        <div class="menuBar">
          <!-- 手机下单 -->
          <div class="barItem" @mouseover="ershow(1)" @mouseleave="ershow(2)">
            <span>手机下单</span>
            <img src="../assets/images/erqeima.png" alt="二维码" class="erweima" v-show="erweiShow" />
          </div>
          <!-- 使用帮助 -->
          <el-dropdown @command="goToUsers">
            <span class="el-dropdown-link">
              使用帮助
              <i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="a">
                平台介绍
              </el-dropdown-item>
              <el-dropdown-item command="b">使用指南</el-dropdown-item>
              <el-dropdown-item command="c">量体介绍</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <!-- <div
            class="barItem"
            :class="barIndex == 4 ? 'attred' : ''"
            @mouseover="userShow(3)"
            @mouseleave="userShow(4)"
          >
            <span>使用帮助</span>
            <div class="userList" v-show="userListShows">
              <span class="usericons el-icon-caret-top"></span>

              <div class="userListItem" @click="goToUser('/about', 4)">
                平台介绍
              </div>
              <div class="userListItem" @click="goToPDF(2)">使用指南</div>
              <div class="userListItem" @click="goToPDF(1)">量体介绍</div>
            </div>
          </div> -->
          <!-- 使用指南 -->
          <!-- <div class="barItem" @click="goToPDF(2)">
            <span>使用指南</span>
          </div>-->
          <!-- 量体介绍 -->
          <!-- <div class="barItem" @click="goToPDF(1)">
            <span>量体介绍</span>
          </div>-->
          <!-- 购物车 -->
          <div class="barItem" :class="barIndex == 3 ? 'attred' : ''" @click="goToUser('/shopCart', 3)" v-show="status"
            style="max-width: 50px; min-width: 50px;">
            <el-badge :value="shopCartNum" class="badgeI" type="info">
              <i class="el-icon-shopping-cart-2"></i>
            </el-badge>
          </div>
          <!-- 消息 -->
          <div class="barItem" :class="barIndex == 1 ? 'attred' : ''" @click="goToUser('/usernews/0', 1)"
            v-show="status" style="max-width: 50px; min-width: 50px;">
            <el-badge :value="this.news" class="badgeI" type="info" :hidden="this.news == 0 ? true : false">
              <i class="el-icon-bell"></i>
            </el-badge>
          </div>
          <!-- 个人中心 -->
          <el-dropdown v-show="abc" style="margin-right: 20px;">
            <span class="el-dropdown-link">
              <i class="el-icon-user" style="font-size: 22px;"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item>
                <el-button type="text" @click="goToUser('/usermy', 2)">
                  账户信息
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item v-show="isIndex">
                <el-button type="text" @click="goToUser('/usertwo', 2)">
                  我的子账号
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button type="text" @click="goToUser('/usernews/0', 2)">
                  系统通知
                </el-button>
              </el-dropdown-item>
              <el-dropdown-item>
                <el-button type="text" @click="removes">
                  退出登录
                </el-button>
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
          <el-button type="cy" v-show="!abc" @click="goToUser('/login')">
            登录
          </el-button>
        </div>
      </div>
      <div class="mains">
        <router-view :defaultHeight="defaultHeight" @childFn="parentFn" :ismodelas="isModel"></router-view>
        <!-- <a href="javascript:;">5555555</a> -->
      </div>
      <div class="footer">
        <Footer />
      </div>
    </div>
  </div>
</template>

<script>
import { notreadmsg, isokpaypas } from '../untils/api'
import Header from '../components/Header.vue'
import Footer from '../components/Footer.vue'
import {
  selectcarcount,
  isindexs,
  selepriceshow,
  selepritdshow,
} from '../untils/api'

export default {
  name: 'Main',
  components: { Header, Footer },

  data() {
    return {
      isRouterAlive: true,
      defaultHeight: '',
      codesss: '',
      menuList: [
        { id: 1, url: '/', text: '首页', status: true },
        { id: 2, url: '/custfashion', text: '下单', status: true },
        { id: 3, url: '/orders', text: '订单', status: false },
        { id: 4, url: '/capital', text: '资金', status: false },
        { id: 5, url: '/custom', text: '客户', status: false },
        { id: 6, url: '/address', text: '地址', status: false },
        { id: 7, url: '/shop', text: '商城', status: true },
        { id: 8, url: '/shouhou', text: '售后', status: false },
        { id: 9, url: '/video', text: '视频', status: false },
      ],
      index: sessionStorage.getItem('url')
        ? sessionStorage.getItem('url')
        : '/',
      erweiShow: false,
      userListShow: false,
      userListShows: false,
      userFocusNum: 0,
      news: '',
      shopCartNum: 0,
      abc: false,
      status: false,
      barIndex: '',
      isIndex: true,
      isModel: 1,
    }
  },
  computed: {},
  watch: {
    $route() {
      this.getInfo()
      this.getnotreadmsg()
      this.getShopCart()
      this.getseleshow()
      this.getselepritdshow()
      if (sessionStorage.getItem('url') == '/shopCart') {
        this.barIndex = 3
        this.index = ''
      }
    },
  },
  created() {
    // 判断是否登录
    if (localStorage.getItem('codes')) {
      sessionStorage.setItem('codes', localStorage.getItem('codes'))
      this.abc = true
      for (let index = 0; index < this.menuList.length; index++) {
        this.menuList[index].status = true
        this.status = true
      }
    } else {
      if (sessionStorage.getItem('url')) {
        let uurl = sessionStorage.getItem('url')
        sessionStorage.clear()
        sessionStorage.setItem('url', uurl)
        this.abc = false
        this.status = false
      } else {
        // let uurl = sessionStorage.getItem('url')
        sessionStorage.clear()
        sessionStorage.setItem('url', '/')
        this.index = '/'
        this.abc = false
        this.status = false
      }
    }
    this.getInfo()
    //页面创建时执行一次getHeight进行赋值，顺道绑定resize事件
    window.addEventListener('resize', this.getHeight)
    this.getHeight()
    this.getnotreadmsg()
    this.getShopCart()
    this.getselepritdshow()
  },

  mounted() {
    if (sessionStorage.getItem('url') == '/shopCart') {
      this.barIndex = 3
    }
    if (sessionStorage.getItem('url') == '/') {
      this.index = '/'
    }
  },

  methods: {
    parentFn(payload) {
      if (payload == 1) {
        this.abc = true
        for (let index = 0; index < this.menuList.length; index++) {
          this.menuList[index].status = true
          this.status = true
        }
      }
    },

    handleSelect(key, keyPath) { },
    // 获取消息数量
    async getnotreadmsg() {
      const res = await notreadmsg({
        codes: sessionStorage.getItem('codes'),
      })
      if (res.data.status == 1) {
        this.news = res.data.data.count
      } else {
        // this.$message.error(res.data.msg)
      }
    },
    // 是否展示3d模型
    async getselepritdshow() {
      const res = await selepritdshow({
        codes: sessionStorage.getItem('codes'),
      })
      if (res.data.status == 1) {
        sessionStorage.setItem('isModel', res.data.data)
        this.isModel = res.data.data
      } else {
        // this.$message.error(res.data.msg)
      }
    },
    // 获取是否显示价格
    async getseleshow() {
      const res = await selepriceshow({
        codes: sessionStorage.getItem('codes'),
      })
      sessionStorage.setItem('selepriceShow', res.data.data)
    },
    // 获取购物车数量
    async getShopCart() {
      const res = await selectcarcount({
        codes: sessionStorage.getItem('codes'),
      })
      this.shopCartNum = res.data.count
    },
    //定义方法，获取高度减去头尾
    getHeight() {
      this.defaultHeight = window.innerHeight - 88
    },
    goToPDF(i) {
      if (i == 1) {
        sessionStorage.setItem('pdfTyle', 1)
        let aaa = this.$router.resolve({
          name: 'Introduce',
          params: {
            type: i,
          },
        })
        window.open(aaa.href, '_blank')
      } else {
        sessionStorage.setItem('pdfTyle', 2)
        let aaa = this.$router.resolve({
          name: 'Introduces',
          params: {
            type: i,
          },
        })
        window.open(aaa.href, '_blank')
      }
    },
    // 跳转页面
    goToMenu(menuUrl) {
      let ucs = menuUrl
      // 浏览器存储路径
      sessionStorage.setItem('url', menuUrl)
      this.index = menuUrl
      this.barIndex = 0
      if (menuUrl == '/custfashion') {
        sessionStorage.removeItem('modelInfo')
        sessionStorage.removeItem('fun')
        sessionStorage.removeItem('fabricInfo')
        sessionStorage.removeItem('niukou')
        sessionStorage.removeItem('styles')
        sessionStorage.removeItem('embdata')
        sessionStorage.removeItem('printdata')
        sessionStorage.removeItem('fabric_sub')
        sessionStorage.removeItem('is_fabric_sub')
        sessionStorage.removeItem('is_group')
        sessionStorage.removeItem('goodsimg')
        sessionStorage.removeItem('is_fun')
        sessionStorage.removeItem('tmpnum')
        sessionStorage.removeItem('tmpid')
        sessionStorage.removeItem('sizeData')
        sessionStorage.removeItem('subdata')
        sessionStorage.removeItem('gprice')
        sessionStorage.removeItem('mianPrice')

        sessionStorage.removeItem('adrrtdiscount_client')
        sessionStorage.removeItem('adrrtprice')
        sessionStorage.removeItem('adrrtdiscount')
        sessionStorage.removeItem('adrrtdiscount_store')
        sessionStorage.removeItem('adrrtbody_price')
        sessionStorage.removeItem('adrrtpart_price')

        sessionStorage.removeItem('banId')
        sessionStorage.removeItem('niuprice')
        sessionStorage.removeItem('banType')
        sessionStorage.removeItem('fun')
        sessionStorage.removeItem('sizejj')
        sessionStorage.removeItem('gongnengprice')

        sessionStorage.setItem('again_design_type', 0)
      }
      // 判断是否登录
      if (menuUrl == '/' || menuUrl == '/home') {
        this.$router.push(menuUrl)
      } else {
        if (localStorage.getItem('codes')) {
          sessionStorage.setItem('codes', localStorage.getItem('codes'))
          this.abc = true
          for (let index = 0; index < this.menuList.length; index++) {
            this.menuList[index].status = true
            this.status = true
          }
          if (menuUrl == '/capital' || menuUrl == '/custom') {
            if (localStorage.getItem('codes')) {
              if (sessionStorage.getItem('zhifu')) {
                let zhifus = JSON.parse(sessionStorage.getItem('zhifu'))
                if (
                  parseInt(new Date().getTime() / 1000) + '' - zhifus.time >
                  7200000
                ) {
                  sessionStorage.removeItem('zhifu')
                  this.$prompt('请输入操作密码（支付密码）：', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    inputType: 'password',
                  })
                    .then(async ({ value }) => {
                      const res = await isokpaypas({
                        codes: sessionStorage.getItem('codes'),
                        passwords: value,
                      })
                      let zhifu = {
                        time: parseInt(new Date().getTime() / 1000) + '',
                        zhifupassword: value,
                      }
                      sessionStorage.setItem('zhifu', JSON.stringify(zhifu))
                      if (res.data.status == 1) {
                        this.$router.push(menuUrl)
                      } else {
                        this.$message.error(res.data.msg)
                      }
                    })
                    .catch(() => {
                      this.$message({
                        type: 'info',
                        message: '取消输入',
                      })
                    })
                } else {
                  this.$router.push(menuUrl)
                }
              } else {
                this.$prompt('请输入操作密码（支付密码）：', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  inputType: 'password',
                })
                  .then(async ({ value }) => {
                    const res = await isokpaypas({
                      codes: sessionStorage.getItem('codes'),
                      passwords: value,
                    })
                    if (res.data.status == 1) {
                      let zhifu = {
                        time: parseInt(new Date().getTime() / 1000) + '',
                        zhifupassword: value,
                      }
                      sessionStorage.setItem('zhifu', JSON.stringify(zhifu))
                      this.$router.push(menuUrl)
                    } else {
                      this.$message.error(res.data.msg)
                    }
                  })
                  .catch(() => {
                    this.$message({
                      type: 'info',
                      message: '取消输入',
                    })
                  })
              }
            }
          } else {
            this.$router.push(menuUrl)
          }


        } else {
          sessionStorage.clear()
          sessionStorage.setItem('url', ucs)
          this.abc = false
          for (let index = 0; index < this.menuList.length; index++) {
            if (
              index == 2 ||
              index == 3 ||
              index == 4 ||
              index == 5 ||
              index == 7
            ) {
              this.menuList[index].status = false
            }

            this.status = false
          }
          this.$message.error('请先登陆')
          this.$router.push('/login')
        }
      }



    },
    goToUsers(command) {
      switch (command) {
        case 'a':
          this.goToUser('/about', 4)
          break
        case 'b':
          this.goToPDF(2)
          break
        case 'c':
          this.goToPDF(1)
          break
        default:
          break
      }
    },
    goToUser(menuUrl, k) {
      this.index = 0
      this.barIndex = k

      if (
        menuUrl == '/usermy' ||
        menuUrl == '/usertwo' ||
        menuUrl == '/usernews/0'
      ) {
        if (localStorage.getItem('codes')) {
          if (sessionStorage.getItem('zhifu')) {
            let zhifus = JSON.parse(sessionStorage.getItem('zhifu'))
            if (
              parseInt(new Date().getTime() / 1000) + '' - zhifus.time >
              7200000
            ) {
              sessionStorage.removeItem('zhifu')
              this.$prompt('请输入操作密码（支付密码）：', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                inputType: 'password',
              })
                .then(async ({ value }) => {
                  const res = await isokpaypas({
                    codes: sessionStorage.getItem('codes'),
                    passwords: value,
                  })
                  let zhifu = {
                    time: parseInt(new Date().getTime() / 1000) + '',
                    zhifupassword: value,
                  }
                  sessionStorage.setItem('zhifu', JSON.stringify(zhifu))
                  if (res.data.status == 1) {
                    this.$router.push(menuUrl)
                  } else {
                    this.$message.error(res.data.msg)
                  }
                })
                .catch(() => {
                  this.$message({
                    type: 'info',
                    message: '取消输入',
                  })
                })
            } else {
              this.$router.push(menuUrl)
            }
          } else {
            this.$prompt('请输入操作密码（支付密码）：', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              inputType: 'password',
            })
              .then(async ({ value }) => {
                const res = await isokpaypas({
                  codes: sessionStorage.getItem('codes'),
                  passwords: value,
                })
                if (res.data.status == 1) {
                  let zhifu = {
                    time: parseInt(new Date().getTime() / 1000) + '',
                    zhifupassword: value,
                  }
                  sessionStorage.setItem('zhifu', JSON.stringify(zhifu))
                  this.$router.push(menuUrl)
                } else {
                  this.$message.error(res.data.msg)
                }
              })
              .catch(() => {
                this.$message({
                  type: 'info',
                  message: '取消输入',
                })
              })
          }
        }
      } else {
        if (localStorage.getItem('codes')) {
          sessionStorage.setItem('url', menuUrl)
          this.$router.push(menuUrl)
        } else {
          this.$router.push('/login')
        }
      }
    },
    // 退出登录
    removes() {
      sessionStorage.clear()
      localStorage.removeItem('codes')
      this.abc = false
      for (let index = 0; index < this.menuList.length; index++) {
        if (
          index == 2 ||
          index == 3 ||
          index == 4 ||
          index == 5 ||
          index == 7
        ) {
          this.menuList[index].status = false
        }

        this.status = false
      }
      this.$router.push('/login')
    },
    // 二维码
    ershow(i) {
      if (i == 1) {
        this.erweiShow = true
      } else {
        this.erweiShow = false
      }
    },
    // 判断是否有子账号
    async getInfo() {
      const res = await isindexs({ codes: sessionStorage.getItem('codes') })
      if (res.data.status == 1) {
        if (res.data.data.is_index == 1) {
          this.isIndex = true
        } else {
          this.isIndex = false
        }
      } else {
        // this.$message.error(res.data.msg)
        this.isIndex = false
      }
    },
    // 账户信息
    userShow(i) {
      if (i == 1) {
        this.userListShow = true
        this.userListShows = false
      } else if (i == 2) {
        this.userListShow = false
        this.userListShows = false
      } else if (i == 3) {
        this.userListShow = false
        this.userListShows = true
      } else {
        this.userListShow = false
        this.userListShows = false
      }
    },
  },
}
</script>
<style lang="less" scoped>
.el-button+.el-button {
  margin-left: 0;
}

.homes {
  filter: grayscale(100%);
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: progid:DXImageTransform.Microsoft.BasicImage(grayscale=1);
  -webkit-filter: grayscale(1);
}

.header {
  display: flex;
  height: 48px;
  font-size: 16px;
  background-color: #004d4d;

  .logo {
    flex: 1;
    max-width: 150px;
    margin-top: 4px;
    margin-left: 1%;
    width: 150px;
    height: 40px;
    color: #fff;

    img {
      display: block;
      width: 100%;
      height: 100%;
    }
  }
}

.menu {
  flex: 3;
  display: flex;
  margin-left: 20px;

  .menuItem {
    flex: 1;
    display: flex;
    max-width: 70px;
    justify-content: center;
    align-items: center;
    color: #bfbfbf;
    cursor: pointer;
  }

  .menuItem:last-child {
    min-width: 70px;
  }

  .menuItem:hover {
    color: #fff;
  }

  .active {
    background: #046464 !important;
    border-color: #046464 !important;
    color: #fff !important;
  }

  .menua {
    flex: 1;
    display: flex;
    max-width: 70px;
    justify-content: center;
    align-items: center;

    cursor: pointer;

    a {
      color: #bfbfbf;
      text-decoration: none;
    }
  }

  .menua:hover {
    a {
      color: #fff;
    }
  }
}

.menuBar {
  flex: 3;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  .el-dropdown-link {
    cursor: pointer;
    color: #ffffff;
  }

  .el-icon-arrow-down {
    font-size: 12px;
  }

  .barItem {
    position: relative;
    flex: 1;
    display: flex;
    height: 36px;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 14px;
    max-width: 80px;
    min-width: 80px;
    cursor: pointer;
    z-index: 20;

    i {
      font-size: 22px;
    }

    .erweima {
      position: absolute;
      top: 47px;
      left: -83px;
    }

    .userList {
      position: absolute;
      top: 60px;
      left: -110px;
      width: 160px;
      background-color: #fff;
      padding-top: 10px;
      padding-bottom: 10px;
      box-shadow: 0px 0px 4px 1px #ccc;

      .usericons {
        position: absolute;
        top: -24px;
        right: 3px;
        font-size: 36px;
        color: #fff;
      }

      .userListItem {
        line-height: 30px;
        padding-left: 35px;
        color: #808080;
      }

      .userListItem:hover {
        background-color: #f5f5f5;
        color: #004d4d;
      }
    }
  }

  .barItem:hover {
    color: #fff;
  }

  .attred {
    background: #046464 !important;
    border-color: #046464 !important;
    color: #fff !important;
  }
}

/* 屏幕小于1440px */
// @media screen and (max-width: 1440px) {
//   .header {
//     display: flex;
//     height: 60px;
//     font-size: 14px;
//     background-color: #004d4d;
//     .logo {
//       margin-top: 5px;
//       margin-left: 20px;
//       width: 155px;
//       height: 50px;
//     }
//   }
//   .menu {
//     .menuItem {
//       max-width: 45px;
//     }
//     .menuItem:last-child {
//       min-width: 80px;
//     }
//     .active {
//       color: #fff;
//     }
//   }
//   .menuBar {
//     .barItem {
//       max-width: 70px;
//       min-width: 70px;

//       i {
//         font-size: 20px;
//       }
//       .erweima {
//         top: 47px;
//       }
//       .userList {
//         top: 60px;
//         left: -93px;

//         .usericons {
//           top: -24px;
//           right: 14px;
//         }
//       }
//     }
//   }
// }</style>
